<script lang="ts" setup>
const { t } = useI18n()

const session = useSessionCookie()
const currentUser = useCurrentUserState()
/*
 * Logout link MUST be handled server side to empty the session server-side and client-side.
 * With a redirect.
 */
const logoutHref = computed(() => {
    return `/?logout=1`
})
const emptySession = () => {
    // Empty session client-side
    session.value = undefined
    currentUser.value = null
}
</script>

<template>
    <v-button
        v-if="session?.token"
        tag="a"
        outlined
        :href="logoutHref"
        @click="emptySession"
    >
        {{ t('logout') }}
        <template #icon>
            <SvgIcon
                name="logout"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            />
        </template>
    </v-button>
</template>
