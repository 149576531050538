<script setup lang="ts">
import { InternalRouteName } from '~/constants/internal-route-name'

const { t } = useI18n()
const userNavItems = [
    {
        route: {
            name: InternalRouteName.USER_PROFILE,
        },
        icon: 'account',
        label: t('user.profile_label'),
    },
    {
        route: {
            name: InternalRouteName.USER_RENTALS,
        },
        icon: 'key',
        label: t('user.rentals_label'),
    },
    {
        route: {
            name: InternalRouteName.PRO_INDEX,
        },
        icon: 'truck',
        label: t('user.pro_label'),
    },
    {
        route: {
            name: InternalRouteName.WEB_PAGE_ENTITY,
            params: { slug: 'foire-aux-questions' },
        },
        icon: 'chate',
        label: t('faq_label'),
    },
]

const route = useRoute()

const pageTitle = t('my_account')

const breadcrumbItems = computed(() => {
    const root = {
        label: t('pages.web_pages.index.title'),
        route: { name: InternalRouteName.HOME },
    }

    if (route.name === InternalRouteName.RENTAL_ENTITY) {
        const rentalId = useFetchedRentalData()?.rental.mrcRentalNumero
        return [
            root,
            {
                label: t('user.rentals_label'),
                route: { name: InternalRouteName.USER_RENTALS },
            },
            {
                label: t('user.rental_entity_title', { id: rentalId || route.params.id }),
                route: {
                    name: InternalRouteName.RENTAL_ENTITY,
                    params: { id: route.params.id },
                },
            },
        ]
    }

    return [
        root,
        {
            label: pageTitle,
        },
        {
            label: t(route.name as string),
            url: route.fullPath,
        },
    ]
})

const headerImg = {
    relativePath: 'account-banner.jpg',
    alt: t('account_banner.alt'),
}
</script>

<template>
    <div>
        <header :class="$style.header">
            <VTopBar />
            <VHeader
                :title="pageTitle"
                :ipx-image="headerImg"
                :breadcrumb-items="breadcrumbItems"
                layout="default"
            />
        </header>
        <VColumnedContent class="main-content-width offset-top-sm">
            <template #aside>
                <VAsideHead />
                <nav>
                    <ul :class="$style.list">
                        <li
                            v-for="(item, i) in userNavItems"
                            :key="`${i} + ${item.label}`"
                            :class="$style.item"
                        >
                            <VMenuLink
                                :route="item.route"
                                :label="item.label"
                                class="text-menu-account-m"
                                :class="$style.link"
                            >
                                <template #icon>
                                    <SvgIcon
                                        :name="item.icon"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    />
                                </template>
                            </VMenuLink>
                        </li>
                    </ul>
                </nav>
                <VLogoutButton :class="$style.logout" />
            </template>
            <slot />
        </VColumnedContent>
        <VFooter />
    </div>
</template>

<style lang="scss" module>
@use 'sass:math';

.header {
    --v-header-body-padding-bottom: calc(var(--v-content-offset-top) * -1 + #{rem(48)});
}

.img {
    width: 100%;
    height: auto;
}

.list {
    display: flex;
    flex-direction: column;
    gap: rem(8);
}

.item {
    padding-block: rem(8);
}

.link {
    padding-block: rem(24);

    &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        background-color: color(black-10);
        content: '';
    }

    .item:last-child &::before {
        display: none;
    }
}

.logout {
    margin-top: rem(12);
}
</style>
